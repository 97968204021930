import React, { useContext, useEffect, useState, useForm } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Grid, Link, Box } from '@material-ui/core';
import { AuthContext } from '../../Contexts/AuthContext';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.gastronaut.ai/">
        Gastronaut GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  center: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
  },
}));

const SignIn = ({ history }) => {
  const styles = useStyles();
  const { data, signInUser } = useContext(AuthContext);
  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
  });

  const handleSignIn = async (event) => {
    event.preventDefault();
    setState((st) => ({ ...st, loading: true }));
    await signInUser(state.email, state.password);
    // location.replace('/restaurantlist');
    setState((st) => ({ ...st, loading: false }));
  };

  return (
    <Container maxWidth="xs" component="main">
      <div className={styles.center}>
        <Avatar className={styles.image}></Avatar>
        <Typography component="h1" variant="h5">
          Anmelden
        </Typography>
        <form className={styles.form} onSubmit={handleSignIn}>
          <TextField
            margin="normal"
            required
            variant="outlined"
            id="email"
            label="Email"
            name="email"
            fullWidth="true"
            error={data.signInError}
            value={state.email}
            color="secondary"
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            variant="outlined"
            fullWidth="true"
            id="password"
            label="Passwort"
            name="password"
            type="password"
            error={data.signInError}
            helperText={data.signInError}
            value={state.password}
            color="secondary"
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />

          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSignIn}
            className={styles.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
