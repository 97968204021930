const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const timeToBlock = (time, plus = 0) => {
  if (!time) {
    return NaN;
  }

  if (Math.floor(parseInt(time.split(":")[1])) > 60) {
    return NaN;
  }

  if (parseInt(time.split(":")[0]) > 24) {
    return NaN;
  }

  return (
    parseInt(time.split(":")[0]) * 4 +
    Math.floor((parseInt(time.split(":")[1]) + plus) / 15)
  );
};

export const blockToTime = (block) => {
  var h = Math.floor(block / 4);
  var m = (block % 4) * 15;
  if (m < 10) {
    m = "0" + m;
  }
  if (h >= 24) {
    h -= 24;
  }
  if (h < 10) {
    h = "0" + h;
  }
  return h + ":" + m;
};

export const createRandomId = () => {
  const arr = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");

  var id = "";

  for (var i = 0; i < 4; i++) {
    id = id + arr[Math.round(Math.random() * (arr.length - 1))];
  }

  return id;
};

export const isBlocked = (blockedAt, time, wDay) => {
  return (
    blockedAt.length &&
    timeToBlock(blockedAt[0]) <= timeToBlock(time) &&
    timeToBlock(blockedAt[1]) >= timeToBlock(time)
  );
};

export const checkTable = (table, reservationLength, serviceTill) => {
  var hours = [];
  for (var h = 0; h < serviceTill; h++) {
    if (
      table.bookings.every((b) => h + reservationLength < b.start || h > b.end)
    ) {
      hours.push(h);
    }
  }

  return hours;
};

export const compareArrays = (arrays) => {
  var newArray = arrays.shift();

  for (var n = 0; n < arrays.length; n++) {
    var arrayCompare = [];
    arrays[n].forEach((i) => {
      if (newArray.includes(i)) {
        arrayCompare.push(i);
      }
    });
    newArray = arrayCompare;
  }

  return newArray;
};

export const pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};

export const pluralize = (singular) => {
  return singular + "s";
};

export const objectsAreSame = (x, y) => {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (
      !y[propertyName] ||
      !x[propertyName] ||
      (typeof x[propertyName] === "object" &&
        x[propertyName][0] !== undefined &&
        !x[propertyName].every((h) => y[propertyName].includes(h)))
    ) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
};

// Takes in a list of fields with their type and sometimes a list of options and returns list of all fields with errors
export const checkRequiredFields = (array = [], obj = {}) => {
  var err = [];

  array.forEach((i) => {
    var value = obj[i.key];

    if (i.type === "array") {
      if (typeof value !== "object" || !value.length) {
        err.push(i.key);
      }
    } else if (
      !value ||
      typeof value !== i.type ||
      (i.oneOf && !i.oneOf.includes(value))
    ) {
      err.push(i.key);
    }
  });

  return err;
};

// Returns a partial object and leaves out the provided keys;
export const partialSpread = (obj, leaveOut = []) => {
  var newObj = {};
  var arr = Object.keys(obj).filter((k) => !leaveOut.includes(k));

  arr.forEach((key) => {
    newObj[key] = obj[key];
  });

  return newObj;
};

export const dateHelper = (date = new Date()) => {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  return date?.toISOString()?.split("T")?.[0];
};

export const dateHelperClean = (date = new Date()) => {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  return date.toISOString();
};

export const timeHelper = (dateObj = new Date()) => {
  let block = timeToBlock(dateObj.toTimeString().slice(0, 5));

  return blockToTime(block);
};

export const stringifyDate = (
  date = new Date(),
  showWDay = true,
  showTime = false
) => {
  let time = "";

  if (typeof date === "object") {
    if (showTime) {
      time = " " + date?.toLocaleTimeString()?.slice(0, 5) || "";
    }

    date = dateHelper(date);
  }

  let weekdays = ["So. ", "Mo. ", "Di. ", "Mi. ", "Do. ", "Fr. ", "Sa. "];

  let wday = showWDay ? weekdays[new Date(date).getDay()] : "";

  let parts = date.split("-");

  return `${wday}${parts[2]}.${parts[1]}.${parts[0].slice(2)}${time}`;
};

export const daysFromToday = (date) => {
  if (!date) {
    return 0;
  }

  if (typeof date !== "object") {
    date = new Date(date);
  }

  return dateDiffInDays(new Date(), date);
};

export const stringifyOccassion = (occ) =>
  occ
    .split("-")
    .map((w) => w.charAt().toLocaleUpperCase() + w.slice(1))
    .join(" ");

export const daysInFuture = (days = 1, date = new Date()) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  date.setDate(date.getDate() + days);

  return dateHelper(date);
};

export const stringToId = (str) => {
  return str
    .toLocaleLowerCase()
    .replace(" ", "-")
    .replace(/ö/gm, "oe")
    .replace(/ü/gm, "ue")
    .replace(/ä/gm, "ae")
    .replace(/ß/gm, "ss")
    .replace(/\W/g, "");
};

export const stringifyShortname = (shortName) => shortName;

export const getTodaysDate = () => {
  const now = new Date(Date.now());
  const date = ("0" + now.getDate()).slice(-2);
  const month = ("0" + (now.getMonth() + 1)).slice(-2);
  const year = now.getFullYear();
  return year + "-" + month + "-" + date;
};

export const getNowTimeRounded = () => {
  // Get the actual time rounded 15 min up (exple 16:05 => 16:15)
  let actualHours = new Date().getHours();
  const actualMin = new Date().getMinutes();
  let roundedMin;
  if (actualMin >= 0 && actualMin <= 15) roundedMin = "15";
  if (actualMin > 15 && actualMin <= 30) roundedMin = "30";
  if (actualMin > 30 && actualMin <= 45) roundedMin = "45";
  if (actualMin > 45 && actualMin <= 59) {
    roundedMin = "00";
    actualHours += 1;
  }
  return ("0" + actualHours).slice(-2) + ":" + roundedMin;
};

export const formatedDateAndTime = (dateAndTime) => {
  //from format 2020-11-20T10:30 to format 20.11.2020 10:30
  let date = dateAndTime.split("T")[0];
  let year = date.split("-")[0];
  let month = date.split("-")[1];
  let day = date.split("-")[2];
  let time = dateAndTime.split("T")[1];

  return (
    day + "." + month + "." + year + (time !== undefined ? "  " + time : "")
  );
};

export const timeToMin = (time = "10:00") => {
  let hours = time.split(":")[0];
  let minutes = time.split(":")[1];
  return +hours * 60 + +minutes; //600
};

export const minToTime = (m = 615) => {
  let hours = "0" + Math.floor(m / 60); // 10
  let minutes = "0" + (m % 60); //15
  return hours.slice(-2) + ":" + minutes.slice(-2); //10:15
};

export const navigatorVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

// Exple : if the date in props is the 3rd Monday of March => will return 3
export const thisIsTheXthWeekdayOfTheMonth = (date) => {
  if (date.getDate() < 7) {
    return 1;
  }

  let dayOfMonthCount = new Date(JSON.parse(JSON.stringify(date)));
  dayOfMonthCount.setDate(1);
  // console.log(dayOfMonthCount);
  // console.log(date.getDate());
  let count = 1;
  for (let i = 2; i < date.getDate(); i++) {
    if (dayOfMonthCount.getDay() === date.getDay()) {
      count += 1;
    }
    dayOfMonthCount.setDate(i);
  }
  return count;
};

export const get3NextMonths = (date = Date.now()) => {
  const firstMonth = new Date(date).getMonth() + 1;
  const firstYear = new Date(date).getFullYear();

  const firstDate = firstYear + "-" + ("0" + firstMonth).slice(-2);
  const secondDate =
    firstMonth < 12
      ? firstYear + "-" + ("0" + (firstMonth + 1)).slice(-2)
      : firstYear + 1 + "-01";
  const thirdDate =
    firstMonth < 11
      ? firstYear + "-" + ("0" + (firstMonth + 2)).slice(-2)
      : firstMonth === 11
      ? firstYear + 1 + "-01"
      : firstYear + 1 + "-02";

  return [firstDate, secondDate, thirdDate];
};

export const compareDates = (date1, date2) => {
  const day1 = +date1.getDate();
  const day2 = +date2.getDate();
  const month1 = +date1.getMonth();
  const month2 = +date2.getMonth();
  const year1 = +date1.getFullYear();
  const year2 = +date2.getFullYear();
  return day1 === day2 && month1 === month2 && year1 === year2;
};

export const customFieldArr = (
  { custom = {}, customFields = [] },
  frontend = true
) => {
  return Object.keys(custom)
    .map((name) => {
      let field = customFields.map((f) => f.name === name) || null;

      if (!field || (field.hideFrontend && frontend)) return null;

      let value = custom[name];

      if (field.type === "option") {
        value =
          (field?.options?.find((o) => o.id === value) || {}).label || value;
      }

      return {
        name,
        label: field.label,
        translations: field.translations,
        value: custom[name],
        type: field.type,
      };
    })
    .filter((f) => !!f && (f.value || f.value === 0));
};

export const moAsWDay = (wDay) => (wDay + 6) % 7;

const translations = {
  de: {
    months: [
      ,
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    days: [
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
      "Sonntag",
    ],
    wDays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    today: "Heute",
    tomorrow: "Morgen",
  },
  en: {
    months: [
      ,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    days: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    wDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    today: "Today",
    tomorrow: "Tomorrow",
  },
};

export const dateDiffInDays = (a, b = new Date()) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const toEuropeanDate = (
  date = dateHelper(),
  short = true,
  addWDay = false
) => {
  let [yy, mm, dd] = date.split("-");

  let wday = "";

  if (addWDay) {
    const WDAYS = ["So. ", "Mo. ", "Di. ", "Mi. ", "Do. ", "Fr. ", "Sa. "];
    wday = WDAYS[new Date(date).getDay()];
  }

  if (short) {
    return `${wday}${dd}.${mm}`;
  } else {
    return `${wday}${dd}.${mm}.${yy.slice(2, 4)}`;
  }
};

export const fromDate = (date = new Date(), short = true) => {
  let diff = dateDiffInDays(date);
  // 0 Heute
  // 1 gestern
  // bis 7 Tage
  // console.log(diff, date);
  if (diff === 0) {
    return "Heute";
  } else if (diff === 1) {
    return "Gestern";
  } else if (diff < 7) {
    let wDay = date.getDay();
    const WDAYS = ["So. ", "Mo. ", "Di. ", "Mi. ", "Do. ", "Fr. ", "Sa. "];

    return WDAYS[wDay];
  } else {
    return toEuropeanDate(dateHelper(date), short);
  }
};
