import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button, Box, Paper, Typography } from '@material-ui/core';
import { Directions, SupervisorAccount, WebRounded } from '@material-ui/icons';
import { db, FieldValue } from 'utils/firebase';
import { stringifyDate } from 'utils/helperFunctions';
import RestaurantInformation from './RestaurantInformation';
import { RestaurantContext } from '../../../Contexts/RestaurantContext';
import styles from './RestaurantInformation.module.css';
import SystemCheck from './SystemCheck';

const STATUS_OPTIONS = [
  {
    id: 'testphase',
    title: 'Testphase',
    color: 'orange',
  },
  {
    id: 'inactive',
    title: 'Inaktiv',
    color: 'yellow',
  },
  {
    id: 'production',
    title: 'Live',
    color: '#0bdbc5;',
  },
];

const PRODUCT_LINKS = [
  // {
  //   id: 'general',
  //   link: (rId) => `https://app.gastronaut.ai/${rId}/all/home`,
  //   label: 'Dashboard',
  //   icon: <SupervisorAccount />,
  // },
  {
    id: 'reservation',
    link: (rId) => `https://reservation.gastronaut.ai/${rId}`,
    label: 'Reservation Plugin',
    icon: '',
  },
  {
    id: 'followUp',
    link: (rId) => `https://app.gastronaut.ai/${rId}/feedback`,
    label: 'Feedback',
    icon: '',
  },
  {
    id: 'menu',
    link: (rId) => `https://app.gastronaut.ai/${rId}/menu`,
    label: 'Digital Menu',
    icon: '',
  },
  {
    id: 'delivery',
    link: (rId) => `https://lieferdienst.gastronaut.ai/${rId}`,
    label: 'Delivery',
    icon: '',
  },
  // {
  //   id: 'merchandise',
  //   link: (rId) => `https://gastronaut-shop-duev3.ondigitalocean.app/${rId}`,
  //   label: 'Merchandise Shop',
  //   icon: '',
  // },
];

const RestaurantInformationContainer = () => {
  const anchorEl = useRef(null);
  const [open, setopen] = useState(false);
  const [products, setproducts] = useState([]);

  const { restaurants, current, setcurrent, active, checkOpen, setcheckOpen, testRestaurant, settestRestaurant } = useContext(RestaurantContext);

  const restaurant = current ? restaurants.find((r) => r.id === current) : null;

  const restaurantProducts = PRODUCT_LINKS.filter((p) => restaurant?.productsOwned?.includes(p.id));

  const customerRef = db.collection('restaurantData').doc(restaurant?.id);

  let updated = !!restaurant?.updatedAt ? stringifyDate(new Date(restaurant?.updatedAt)) : 'createdAt';

  useEffect(() => {
    setproducts(restaurant?.productsOwned || []);
    return () => {};
  }, [restaurant]);

  console.log(checkOpen);
  console.log(restaurant);

  const handleCheckOpen = () => {
    setcheckOpen(!checkOpen);
  };

  return (
    <Box component="div" className={styles.rightSide}>
      {!checkOpen && (
        <RestaurantInformation
          current={current}
          setcurrent={setcurrent}
          statusOptions={STATUS_OPTIONS}
          productLinks={PRODUCT_LINKS}
          open={open}
          setopen={setopen}
          products={products}
          setproducts={setproducts}
          restaurant={restaurant}
          restaurantProducts={restaurantProducts}
          customerRef={customerRef}
          updated={updated}
          checkOpen={checkOpen}
          onCheckOpen={handleCheckOpen}
          testRestaurant={testRestaurant}
          settestRestaurant={settestRestaurant}
        />
      )}
      {checkOpen && <SystemCheck restaurantId={restaurant?.id} onCheckOpen={handleCheckOpen} restaurantName={restaurant?.name} />}
    </Box>
  );
};

export default RestaurantInformationContainer;
