import React from "react";
import { DataGrid } from "@material-ui/data-grid";
// import { useDemoData } from "@material-ui/x-grid-data-generator";
import TreeView from "@material-ui/lab/TreeView";
import {
  Button,
  Container,
  TextField,
  Box,
  ButtonGroup,
  Paper,
  Typography,
} from "@material-ui/core";

import Label from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TreeElement from "./ListElement";

const List = ({ errors, states, setfilter }) => {
  return (
    <div
      style={{
        width: "100%",
        paddingTop: 10,
        overflow: "scroll",
        height:"90%"
      }}
    >
      <Box>
        <TreeView
          defaultExpanded={["3"]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          style={{ minHeight: "200px", maxHeight: "600px" }}
        >
          {states?.map(({ id, length, children = [] }) => (
            <TreeElement
              key={id}
              onClick={() => setfilter({ status: id })}
              nodeId={id}
              labelText={id}
              labelIcon={Label}
              labelInfo={length}
              style={{ marginBottom: 20, marginRight: 10 }}
            >
              {children.map((product) => (
                <TreeElement
                  key={product.id}
                  onClick={() => setfilter({ status: id, product: product.id })}
                  nodeId={`${id}-${product.id}`}
                  labelText={`${product.id}`}
                  labelIcon={Label}
                  labelInfo={product.length}
                ></TreeElement>
              ))}
            </TreeElement>
          ))}
        </TreeView>
      </Box>
    </div>
  );
};

export default List;
