import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, TextField, Box, ButtonGroup, Paper, Typography } from '@material-ui/core';
import ChartContainer from './Main/Chart';
import List from './Main/List';
import { HealthContext } from 'Contexts/HealthContext';
import { dateHelper, timeHelper } from '../../utils/helperFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 80px)',
    width: 'calc(100vw - 200px)',
    background: 'var(--color-depth4)',
    display: 'flex',
    flexDirection: 'column',
  },
  chartContainer: {
    width: 'calc(100vw - 230px)',
    flexGrow: 10,
    margin: 15,
    display: 'flex',
    flexDirection: 'row',
  },
  barContainer: {
    // maxHeight: "310px",
    // minHeight: "310px",
    width: '70%',
    padding: 15,
    marginRight: 15,
  },
  mostAppearedContainer: {
    width: '30%',
    // minHeight: "310px",
    padding: 15,
    overflow: 'scroll',
  },
  listContainer: {
    width: '600px',

    // maxHeight: "600px",
    // minHeight: "200px",

    margin: 15,
    marginTop: 5,
    padding: 15,
    marginRight: 0,
  },
  detailListContainer: {
    width: '100%',
    margin: 15,
    marginTop: 5,
    padding: 15,
    overflow: 'scroll',
    // height: "calc(100vh - 310px)",
  },
}));

const TabButton = ({ label, tab, day, onTabChange }) => (
  <Button
    size="small"
    style={{ marginRight: 15, marginBottom: 10 }}
    variant="contained"
    disableElevation
    color={tab === day ? 'primary' : 'default'}
    onClick={onTabChange(day)}
  >
    {label}
  </Button>
);

const HealthScreen = () => {
  const styles = useStyles();

  const { errors, states, tab, settab, filter, setfilter, filteredErrors, timeFiltered } = useContext(HealthContext);

  const handleTabChange = (day) => () => settab(day);
  // console.log(filteredErrors);
  return (
    <Box className={styles.container}>
      <Box className={styles.chartContainer}>
        <Paper className={styles.barContainer}>
          <div>
            <TabButton label="Heute" tab={tab} day={1} onTabChange={handleTabChange} />
            <TabButton label="Woche" tab={tab} day={7} onTabChange={handleTabChange} />
            <TabButton label="Monat" tab={tab} day={30} onTabChange={handleTabChange} />
            <TabButton label="Jahr" tab={tab} day={365} onTabChange={handleTabChange} />
          </div>
          {/* <ChartContainer /> */}
        </Paper>
        <Paper className={styles.mostAppearedContainer}>
          <Typography variant="h6">15 Minuten Ticker</Typography>
          {timeFiltered?.map?.((t) => (
            <div
              style={{
                paddingTop: 10,
              }}
              key={t.id}
            >
              <p>{`Error:   ${t.error}`}</p>
              <p>{`Hostname: ${t.hostname}`}</p>
              <p>{`Pfad:     ${t.path}`}</p>
            </div>
          ))}
        </Paper>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '60%',
        }}
      >
        <Paper className={styles.listContainer}>
          <div>
            <TabButton label="Heute" tab={tab} day={1} onTabChange={handleTabChange} />
            <TabButton label="7 Tage" tab={tab} day={7} onTabChange={handleTabChange} />
            <TabButton label="30 Tage" tab={tab} day={30} onTabChange={handleTabChange} />
          </div>
          <List errors={errors} states={states} setfilter={setfilter} />
        </Paper>
        <Paper className={styles.detailListContainer}>
          <div>
            {filteredErrors?.map((e) => (
              <div key={e.ts}>
                <p style={{ padding: 10 }}>{`${dateHelper(e.ts)} ${timeHelper(new Date(e.ts))} · ${e.status}`}</p>
                <div style={{ paddingLeft: 40 }}>
                  <p>{`Error:    ${e.error}`}</p>
                  <p>{`Hostname: ${e.hostname}`}</p>
                  <p>{`Pfad:     ${e.path}`}</p>
                </div>
              </div>
            ))}
          </div>
        </Paper>
      </Box>
    </Box>
  );
};

export default HealthScreen;
