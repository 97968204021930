import { checkPropTypes } from 'prop-types';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button, TextField } from '@material-ui/core';
import { AuthContext } from 'Contexts/AuthContext';

const NewToDo = ({ onNew = () => {}, ...props }) => {
  const [message, setmessage] = useState('');
  const [type, settype] = useState('todo');

  const { getSender } = useContext(AuthContext);

  //   const handleChange = ({ target: { checked } }) => settype(checked);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newItem = {
      id: String(Math.floor(Math.random() * 1000000)),
      type,
      sender: getSender(),
      createdAt: Date.now(),
      done: false,
      message,
    };

    onNew(newItem);
    setmessage('');
  };

  return (
    <div style={{ alignItems: 'flex-end' }}>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <TextField
          select
          onChange={(e) => settype(e.target.value)}
          value={type}
          style={{ minWidth: 50 }}
        >
          <MenuItem value="todo">☑️</MenuItem>
          <MenuItem value="note">✏️</MenuItem>
        </TextField>
        <TextField
          value={message}
          onChange={(e) => setmessage(e.target.value)}
          style={{ flexGrow: 10 }}
        />
        <Button type="submit"> add </Button>
      </form>
    </div>
  );
};

export default NewToDo;
