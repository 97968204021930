import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { db, rtDB, FieldValue } from 'utils/firebase';
import { withRouter } from 'react-router-dom';
import useErrors from 'CustomHooks/useErrors';
// import json from "./schillingroofbarhd-api-error-logger-export.json";

export const HealthContext = createContext();

const HRS_24 = 24 * 60 * 60000;
const getTimestamp = (days) => Date.now() - HRS_24 * days;

const MIN_IN_MILISECONDS = 1000 * 60;
let accuracy = 15; // in Minuten

const HealthContextProvider = ({ history, children }) => {
  const [tab, settab] = useState(1);
  const { errors, loading } = useErrors(tab);
  const [filter, setfilter] = useState({});
  const [state, setstate] = useState(false);

  // const states = useMemo(() => {
  const keyList = errors.reduce((acc, cV) => {
    let { status } = cV;
    if (!acc[status]) {
      acc[status] = [cV];
    } else {
      acc[status].push(cV);
    }
    return acc;
  }, {});

  const states = Object.keys(keyList).map((id) => {
    const items = keyList[id];
    const childrenObj = items.reduce((acc, cV) => {
      const { path, id } = cV;
      const product = path.startsWith('/v02') ? path.split('/')[2] : 'other';
      if (!acc[product]) {
        acc[product] = [cV];
      } else {
        acc[product].push(cV);
      }
      return acc;
    }, {});

    const children = Object.keys(childrenObj).map((id) => {
      const items = childrenObj[id];
      return {
        id,
        length: items.length,
      };
    });
    return {
      id,
      length: items.length,
      children,
    };
  });
  // }, [errors]);

  const filteredErrors = useMemo(() => {
    return errors.filter((err) => {
      // console.log('ERROR:', err);
      let hasStatus = !filter.status || filter.status == err.status;
      let isOther = !err.path.startsWith('/v02') && filter.product === 'other';
      let isProduct = err.path.split('/')[2] === filter.product;
      let hasProduct = !filter.product || isOther || isProduct;

      return hasStatus && hasProduct;
    });
  }, [errors, filter]);

  const timeFiltered = useMemo(() => {
    return filteredErrors
      .map((err) => {
        let factor = MIN_IN_MILISECONDS * accuracy;

        let time = Math.round(err.ts / factor) * factor;

        return { ...err, time };
      })
      .reduce((acc, cV) => {
        console.log('CC', acc);
        if (!acc[cV.time]) {
          acc[cV.time] = [cV];
        } else {
          acc[cV.time].push(cV);
        }

        return acc;
      }, {});
  }, []);

  // console.log("TIME", timeFiltered);

  // const timeArr = Object.keys(timeFiltered).map(tf => {

  //   return tf.id
  // })

  return (
    <HealthContext.Provider
      value={{
        errors,
        loading,
        tab,
        settab,
        states,
        setfilter,
        filteredErrors,
        timeFiltered,
      }}
    >
      {children}
    </HealthContext.Provider>
  );
};
export default withRouter(HealthContextProvider);
