import React, { createContext, useState, useEffect } from 'react';
import firebase from 'utils/firebase';

// import 'firebase/analytics';
import 'firebase/auth';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import LoadingScreen from '../Components/LoadingScreen';

export const AuthContext = createContext();

// firebase.analytics();
const db = firebase.firestore();
// const FieldValue = firebase.firestore.FieldValue;

const isMessagingSupported =
  (navigator.userAgent.includes('Chrome') ||
    navigator.userAgent.includes('Android')) &&
  window.location.protocol.includes('https');

if (isMessagingSupported) {
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => console.log('onMessage:', payload));
}

const AuthContextProvider = ({ history, children, ...props }) => {
  const [data, setdata] = useState({});
  // const [invitationState, setinvitationState] = useState({
  //   new: true,
  //   loading: true,
  //   error: null,
  // });

  const signInUser = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        let errorMessage = error.message;
        // ...
        // console.log(errorCode, errorMessage)

        setdata({ ...data, signInError: errorMessage });
      });
  };

  const signOutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        history.push('/signin');
        setdata((d) => ({ ...d, user: false }));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (data.user === undefined) {
      var user = firebase.auth().currentUser;

      console.log({ user });

      if (user) {
        checkUser(user);
        //signOutUser();
      }
    }
  }, [data.user]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log(user);
        checkUser(user);
      } else {
        signOutUser();
      }
    });
  }, []);

  const checkUser = async (user) => {
    const userDoc = await db.collection('users').doc(user.uid).get();
    if (!userDoc.exists || !userDoc.data().isGastronautAdmin) {
      signOutUser();
      setdata((d) => ({ ...d, signInError: 'Kein Gastronaut Admin' }));
    } else {
      setdata((d) => ({ ...d, user }));
      if (window.location.pathname.includes('signin')) {
        // if (!window.location.pathname.includes('dashboard')) {
        history.push('/admin-dashboard/dashboard');
      }
    }
    return;
  };

  const getSender = () => {
    return {
      uid: data?.user?.uid,
      displayName: data?.user?.displayName,
    };
  };

  return (
    <AuthContext.Provider
      value={{
        db,
        signInUser,
        data,
        signOutUser,
        getSender,
      }}
    >
      <LoadingScreen
        loading={!data.user && document.location.pathname !== '/signin'}
      />
      {children}
    </AuthContext.Provider>
  );
};

export default withRouter(AuthContextProvider);
