import React, { createContext, useState, useContext, useEffect, useMemo, useRef } from 'react';
import { db, rtDB, FieldValue } from '../utils/firebase';
import { timeHelper, fromDate, dateHelper } from '../utils/helperFunctions';

const useCallList = (filter) => {
  const [list, setlist] = useState([]);
  const [listener, setlistener] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (listener.length) {
      listener.forEach((unsubscribe) => unsubscribe());
    }

    setloading(true);
    const callRef = db.collection('calls').orderBy('createdAt', 'desc').limit(150);

    let unsubscribe = callRef.onSnapshot((querySnapshots) => {
      let arr = [];
      querySnapshots.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      setlist(arr);
      setloading(false);
    });
    setlistener([unsubscribe]);

    return () => {
      if (listener.length) {
        listener.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [db]);

  // useEffect(() => {
  //   db.collection("calls")
  //     .get()
  //     .then((docs) => {
  //       let arr = [];
  //       docs.forEach((doc) => {
  //         arr.push({ ...doc.data(), id: doc.id });
  //       });
  //       setlist(arr);
  //     });
  // }, []);

  const callList = useMemo(() => {
    return list
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((call) => {
        const success = call.success ?? !!call?.events?.[call.events.length - 1]?.success;

        const date = dateHelper(call?.createdAt);
        const time = new Date(call?.createdAt).toLocaleString().split(',')[1].slice(1, 6);
        return {
          SessionId: call.id,
          dateStr: date ? `${fromDate(new Date(date), false)} - ${time}` : '',

          ...call,
          success,
          callerId: call.callerId === '+4980060000' ? 'Interner Test' : call.callerId,
        };
      })
      .filter((f) => {
        if (filter === 'failed') {
          return !f.success;
        } else if (filter === 'chat') {
          return f?.conversations?.length >= 1;
        } else if (filter === 'rating_1') {
          return f?.rating <= 1;
        } else if (filter === 'rating_2-3') {
          return f?.rating === 2 || f?.rating === 3;
        } else if (filter === 'rating_4-5') {
          return f?.rating >= 4;
        }
        return true;
      });
  }, [list, filter]);

  return callList;
};

export default useCallList;
