import React from "react";
import { Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  labelIcon: {
    margin: 10,
  },
  labelText: {
    flexGrow: 2,
  },
}));

const TreeElement = ({
  labelIcon: LabelIcon,
  labelText,
  labelInfo,
  ...props
}) => {
  const styles = useStyles();
  return (
    <>
      <TreeItem
        style={{ padding: 3 }}
        label={
          <div className={styles.labelRoot}>
            <LabelIcon color="inherit" className={styles.labelIcon} />
            <Typography className={styles.labelText}> {labelText} </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        {...props}
      />
    </>
  );
};

export default TreeElement;
