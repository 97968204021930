import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '40px',
    width: '100%',
  },
  header: {
    fontWeight: '700',
    color: 'var(--color-text)',
  },
}));

const Header = ({ header, children, onClose }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Typography variant="h4" component="h1" className={styles.header}>
        {header}
      </Typography>
      <IconButton onClick={onClose}>
        <CloseRounded />
      </IconButton>
      {/* {children} */}
    </Box>
  );
};

export default Header;
