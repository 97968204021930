import { React, useContext, useState } from 'react';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
} from '@material-ui/core';
import { Avatar } from '@material-ui/core';
// import InboxIcon from '@material-ui/icons/Inbox';
import { AuthContext } from '../Contexts/AuthContext';
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    top: 0,
    zIndex: 100,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  home: {
    // marginRight: theme.spacing(2),
    padding: 20,
  },
  add: {
    // alignItems: 'center',
    // left: 0,
    // flexGrow: 40,
    // fontSize: 30,
    // paddingRight: 80,
  },
  addIcon: {
    // fontSize: 30,
    // paddingBottom: 50,
  },
  logout: {
    color: 'white',
    // marginRight: theme.spacing(4),
    // flexGrow: 2,
  },
}));

const TopBar = ({ history }) => {
  const styles = useStyles();
  const { data, signOutUser } = useContext(AuthContext);

  if (!!data.user) {
    return (
      <div className={styles.root}>
        <AppBar position="static" style={{ background: '#000000' }}>
          <Toolbar className={styles.toolbar}>
            <IconButton
              color="inherit"
              className={styles.home}
              onClick={() => history.push('/admin-dashboard/dashboard')}
            >
              <Avatar src="/logo512.png"></Avatar>
            </IconButton>
            {/* <Button
              color="inherit"
              className={styles.add}
              onClick={() => history.push('/onboarding')}
            >
              Onboarding
            </Button>

            <Button
              color="inherit"
              className={styles.health}
              onClick={() => history.push('/health')}
            >
              Health
            </Button> */}

            <Button className={styles.logout} onClick={signOutUser}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  } else {
    return <></>;
  }
};
export default withRouter(TopBar);
