import { React, useState } from "react";
import {
  Box,
  List,
  makeStyles,
  Paper,
  ListItem,
  Button,
  Typography,
  ListItemText,
} from "@material-ui/core";
import { WebRounded } from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import CallIcon from "@material-ui/icons/Call";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "200px",
    height: "100%",
    padding: 10,
  },
  list: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  icon: {
    size: 16,
    marginRight: 15,
  },
  item: {
    marginTop: 10,
    width: "100%",
  },
}));

const tabs = [
  {
    title: "Dashboard",
    icon: WebRounded,
    feature: "dashboard",
  },
  {
    title: "Onboarding",
    icon: AssignmentIndIcon,
    feature: "onboarding",
  },
  {
    title: "Health",
    icon: LocalHospitalIcon,
    feature: "health",
  },
  {
    title: "SIA",
    icon: CallIcon,
    feature: "sia",
  },
];

const NavItem = ({
  className,
  title,
  key,
  active,
  icon: Icon,
  feature,
  history,
}) => {
  const styles = useStyles();
  const [current, setcurrent] = useState(true);

  return (
    <ListItem key={key} className={styles.item} selected={active}>
      <Button
        style={{
          width: "100%",
        }}
        variant="inherit"
        color={!current ? "primary" : "secondary"}
        onClick={() => {
          history.push(`/admin-dashboard/${feature}`);
          setcurrent(title);
        }}
      >
        <Icon className={styles.icon} />
        <ListItemText style={{ marginRight: 50 }}>{title}</ListItemText>
      </Button>
    </ListItem>
  );
};

const NavBar = ({ history, activeItem }) => {
  const styles = useStyles();

  return (
    <>
      <Paper className={styles.root} variant="outlined">
        <List>
          {tabs.map((tab) => (
            <NavItem
              key={tab?.title}
              active={activeItem === tab.feature}
              {...tab}
              history={history}
            />
          ))}
        </List>
      </Paper>
    </>
  );
};

export default withRouter(NavBar);
