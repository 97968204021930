import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { db, rtDB, FieldValue } from "utils/firebase";

const HRS_24 = 24 * 60 * 60000;

const getTimestamp = (days) => Date.now() - HRS_24 * days;

const useErrors = (tab = 7) => {
  const [history, setHistory] = useState([]);
  const [newErrors, setnewErrors] = useState([]);
  const [delayedNew, setdelayedNew] = useState([]);
  const [loading, setloading] = useState(false);
  const time = useRef(Date.now());
  console.log(tab);
  const [listener, setListener] = useState([]);

  //ONCE
  useEffect(() => {
    setloading(true);
    let count = 0;
    let errorRef = rtDB
      .ref("api-error-logger")
      .orderByKey()
      .startAt(String(getTimestamp(tab)))
      .endBefore(String(time.current));

    errorRef.once("value", (snapshot) => {
      let arr = [];
      snapshot.forEach((childSnapshot) => {
        let newData = {
          id: count++,
          ts: parseInt(childSnapshot.key),
          ...childSnapshot.val(),
        };
        arr.push(newData);
      });

      setHistory(arr.sort((a, b) => b.ts - a.ts));
      setloading(false);
    });
  }, [tab, time.current]);

  //ON
  // useEffect(() => {
  //   if (listener.length) {
  //     listener.forEach((ref) => {
  //       ref.off();
  //     });
  //   }
  //   console.log("1");
  //   let errorRef = rtDB
  //     .ref("api-error-logger")
  //     .orderByKey()
  //     .startAt(String(time.current));

  //   errorRef.on("child_added", (data) => {
  //     let newData = {
  //       ts: parseInt(data.key),
  //       ...data.val(),
  //     };
  //     setnewErrors((cV) => [...cV, newData].sort((a, b) => b.ts - a.ts));
  //   });

  //   setListener([errorRef]);

  //   return () => {
  //     if (listener.length) {
  //       listener.forEach((ref) => {
  //         ref.off();
  //       });
  //     }
  //   };
  // }, [time.current]);

  const timeOut = useRef(null);

  //Delayed  with timeout
  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      setdelayedNew(newErrors);
    }, 200);

    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    };
  }, [newErrors]);

  const errors = useMemo(() => {
    return [...delayedNew, ...history];
  }, [history, delayedNew]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log({ newErrors: newErrors.length, delayedNew: delayedNew.length });
  }, [newErrors, delayedNew]);

  return { errors, loading };
};

export default useErrors;
