import axios from 'axios';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { db, rtDB, FieldValue } from '../utils/firebase';
import { timeHelper, fromDate, dateHelper } from '../utils/helperFunctions';

const checkArr = [
  {
    id: 'settings',
    title: 'All Settings are published',
    severity: 'error',
    children: [
      {
        id: 'general',
        title: 'General Settings published',
        severity: 'error',
        children: [
          {
            id: 'restaurantName',
            title: 'Restaurant Name filled out',
            severity: 'error',
          },
          {
            id: 'legalName',
            title: 'Legal Name filled out',
            severity: 'warning',
          },
          {
            id: 'email',
            title: 'Email filled out',
            severity: 'warning',
          },
          {
            id: 'phoneNumber',
            title: 'Phone Number filled out',
            severity: 'warning',
          },
          {
            id: 'url',
            title: 'Website Url filled out',
            severity: 'warning',
          },
          {
            id: 'description',
            title: 'Description filled out',
            severity: 'suggestion',
          },
        ],
      },
      {
        id: 'stylesV02',
        title: 'Identity Settings published',
        severity: 'error',
        children: [
          {
            id: 'logoId',
            title: 'Logo missing',
            severity: 'warning',
          },
          {
            id: 'defaultImageId',
            title: 'Default Image missing',
            severity: 'warning',
          },
          {
            id: 'colorPalette.primaryColor',
            title: 'Primary Color missing',
            severity: 'warning',
          },
        ],
      },
      {
        id: 'address',
        title: 'Address Settings published',
        severity: 'error',
        children: [
          {
            id: 'street',
            title: 'Street missing',
            severity: 'warning',
          },
          {
            id: 'city',
            title: 'City missing',
            severity: 'warning',
          },
          {
            id: 'zipCode',
            title: 'Zip Code missing',
            severity: 'warning',
          },
        ],
      },
      {
        id: 'social-media',
        title: 'Social Media Settings published',
        severity: 'error',
        children: [
          {
            id: 'facebook',
            title: 'No Facebook link',
            severity: 'warning',
          },
          {
            id: 'google',
            title: 'No Google Place Id',
            severity: 'warning',
          },
          {
            id: 'tripAdvisor',
            title: 'No Tripadvisor link',
            severity: 'warning',
          },
          {
            id: 'instagram',
            title: 'No Instagram link',
            severity: 'warning',
          },
        ],
      },
    ],
  },
  {
    id: 'users',
    title: 'Users have been invited',
    severity: 'suggestion',
    children: [
      {
        id: 'hasTabletUser',
        title: 'A Tablet has been registered',
        severity: 'suggestion',
      },
      {
        id: 'hasInvitation',
        title: 'An Invitation was created',
        severity: 'suggestion',
      },
    ],
  },
  {
    id: 'images',
    title: 'Images have been uploaded',
    severity: 'suggestion',
  },
  {
    id: 'menu',
    title: 'Digital Menu is setup correctly',
    severity: 'error',
    children: [
      {
        id: 'meals',
        title: 'There are minimum 2 Meals',
        severity: 'error',
      },
      {
        id: 'menues',
        title: 'There is minimum 1 Menu',
        severity: 'error',
      },
      {
        id: 'categories',
        title: 'There is minimum 1 Category',
        severity: 'error',
      },
      {
        id: 'menu-shifts',
        title: 'There is minimum 1 Shift for the Menu',
        severity: 'error',
      },
    ],
  },
  {
    id: 'resSettings',
    title: 'All Settings are published',
    severity: 'error',
    children: [
      {
        id: 'reservationsV02',
        title: 'Reservation Settings are published',
        severity: 'error',
        children: [
          {
            id: 'occassions',
            title: 'Minimum one Occassion',
            severity: 'error',
          },
        ],
      },
      {
        id: 'feedback',
        title: 'Feedback Settings are published',
        severity: 'error',
        children: [
          {
            id: 'prefferedDestinations',
            title: 'Minimum one Preffered Destination',
            severity: 'error',
          },
          {
            id: 'prefferedDestinationsMissingLink',
            title: 'Link is missing for preffered Destination',
            severity: 'error',
          },
          {
            id: 'lowRatingText',
            title: 'Minimum one Language for a high Rating',
            severity: 'suggestion',
          },
          {
            id: 'lowRatingText',
            title: 'Minimum one Language for a high Rating',
            severity: 'suggestion',
          },
        ],
      },
      {
        id: 'reservationPlugin',
        title: 'Reservation Plugin Settings are published',
        severity: 'error',
        children: [
          {
            id: 'styles.primaryColor',
            title: 'Primary Button Color has been picked',
            severity: 'suggestion',
          },
        ],
      },
    ],
  },
  {
    id: 'floorPlan',
    title: 'Has a published Floor Plan',
    severity: 'error',
    children: [
      {
        id: 'spaces',
        title: 'Has minimum one space',
        severity: 'error',
      },
      {
        id: 'tables',
        title: 'Has multiple tables',
        severity: 'error',
      },
      {
        id: 'tableOccassions',
        title: 'Most tables have occassions',
        severity: 'warning',
      },
      {
        id: 'tableCombinations',
        title: 'Has multiple table combinations',
        severity: 'warning',
      },
    ],
  },
  {
    id: 'shifts',
    title: 'Shifts are good to go',
    severity: 'error',
    children: [
      {
        id: 'minOneRegular',
        title: 'Minimum one regular shift',
        severity: 'error',
      },
      {
        id: 'spaceAndOrOccassion',
        title: 'Regular Shifts have use spaces or occassions',
        severity: 'error',
      },
    ],
  },
  {
    id: 'emailTemplates',
    title: 'Has all required Email Templates',
    severity: 'error',
    children: [
      {
        id: 'confirmationEmail',
        title: 'Confirmation Email is valid',
        severity: 'warning',
      },
      {
        id: 'followUp',
        title: 'Follow Up Email is valid',
        severity: 'warning',
      },
      {
        id: 'reminder',
        title: 'Reminder Email is valid',
        severity: 'warning',
      },
      {
        id: 'canceled',
        title: 'Cancelation Email is valid',
        severity: 'warning',
      },
    ],
  },
  {
    id: 'reservations',
    title: 'Reservations have been made',
    severity: 'warning',
    children: [
      {
        id: 'inHouse',
        title: 'Reservations have been made by Waiters',
        severity: 'warning',
      },
      {
        id: 'website',
        title: 'Reservations have been made over Website',
        severity: 'suggestion',
      },
      {
        id: 'today',
        title: 'Minimum one reservation has been made today',
        severity: 'suggestion',
      },
    ],
  },
];

const reduceIds = (arr, preId = '') => {
  if (!arr) return [];

  return arr.reduce((acc, cV) => {
    return [
      ...acc,
      `${preId}${cV.id}`,
      ...reduceIds(cV.children, `${preId}${cV.id}.`),
    ];
  }, []);
};

const useSystemCheck = (restaurantId = '') => {
  const [state, setstate] = useState({});
  const [listener, setlistener] = useState([]);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState(null);

  useEffect(() => {
    if (listener.length) {
      listener.forEach((unsubscribe) => unsubscribe());
    }

    setloading(true);

    const checkRef = db
      .collection(`restaurants/${restaurantId}/settings`)
      .doc('healthCheck');

    let unsubscribe = checkRef.onSnapshot((snapshot) => {
      if (!snapshot.data()) {
        setstate({});
        console.log('if');
      } else {
        console.log('else');
        setstate(snapshot.data());
      }
      setloading(false);
      setmessage(null);
      return;
    });

    setlistener([unsubscribe]);

    return () => {
      if (listener.length) {
        listener.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [restaurantId]);

  const reload = () => {
    console.log('START');
    setmessage('Kann bis zu 2 Minuten dauern');

    axios.post(
      'https://europe-west3-schillingroofbarhd.cloudfunctions.net/healthCheck',
      {
        restaurantId: restaurantId,
      }
    );
  };

  const healthCheck = useMemo(() => {
    let ids = reduceIds(checkArr);

    const arr = Object.keys(state)
      .sort((a, b) => {
        return ids.indexOf(a) - ids.indexOf(b);
      })
      .map((check) => {
        let [firstId, ...idArr] = check.split('.');

        let checkData = checkArr.find((c) => c.id === firstId);
        idArr.forEach((id) => {
          checkData = checkData?.children?.find((c) => c.id === id) ?? null;
        });

        if (!checkData) return null;

        return {
          ...(checkData ?? {}),
          value: state[check],
          children: undefined,
        };
      })
      .filter((c) => !!c);

    return arr;
  }, [state]);

  return { state, healthCheck, loading, reload, message };
};

export default useSystemCheck;
