import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingScreen = ({ loading = false }) => {
  return (
    <div
      style={{
        display: !loading ? 'none' : 'flex',
        zIndex: 10000,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingScreen;
