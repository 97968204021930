import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
export const RestaurantContext = createContext();

const EMPTY_CUSTOMER_INFO = {
  address: '',
  contactName: '',
  email: '',
  phoneNumber: '',
  url: '',
};

const RestaurantContextProvider = ({ children }) => {
  const { db, data } = useContext(AuthContext);
  const [restaurants, setrestaurants] = useState([]);
  const [listener, setlistener] = useState([]);
  const [loading, setloading] = useState(true);
  const [current, setcurrent] = useState(
    null
    // currentrestaurant: 0,
  );
  const [active, setactive] = useState(false);
  const [checkOpen, setcheckOpen] = useState(false);
  const [testRestaurant, settestRestaurant] = useState(false);
  const [filter, setfilter] = useState('all');

  console.log(filter);

  useEffect(() => {
    if (listener.length) {
      listener.forEach((unsubscribe) => unsubscribe());
    }

    if (!!data.user) {
      setloading(true);
      const ref = db.collection('restaurantData');

      let unsubscribe = ref.onSnapshot((querySnapshots) => {
        let arr = [];
        let ids = [];
        const customersToLoad = [];

        querySnapshots.docChanges().forEach((change) => {
          let {
            name,
            customerInfo = EMPTY_CUSTOMER_INFO,
            isTest = false,
            live = true,
            productsOwned = [],
            status = 'onboarding',
            todos,
            customerCopied,
          } = change.doc.data();

          if (!customerCopied) {
            customersToLoad.push(change.doc.id);
          }

          ids.push(change.doc.id);
          arr.push({
            id: change.doc.id,
            name,
            customerInfo,
            isTest,
            live,
            productsOwned,
            status,
            todos: todos ?? [],
          });
        });

        setrestaurants((res) =>
          [...res.filter((r) => !ids.includes(r.id)), ...arr].sort((a, b) =>
            a.name > b.name ? 1 : 0
          )
        );
        setloading(false);

        // console.log(customersToLoad, ids);

        Promise.all(
          customersToLoad.map(async (id) => {
            const customerRef = db.collection('customers').doc(id);

            const doc = await customerRef.get();

            if (!doc.exists) {
              await ref.doc(id).update({ todos: [], customerCopied: true });
            } else {
              let {
                todos = [],
                link = '',
                phone = '',
                email = '',
                address,
              } = doc.data();

              await ref.doc(id).update({
                customerInfo: {
                  address: address
                    ? `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}`
                    : '',
                  contactName: '',
                  email,
                  phoneNumber: phone,
                  url: link,
                },
                todos,
                customerCopied: true,
              });
            }
          })
        );
      });
      setlistener([unsubscribe]);
    }

    return () => {
      if (listener.length) {
        listener.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [db, data.user]);
  return (
    <RestaurantContext.Provider
      value={{
        restaurants,
        current,
        setcurrent,
        active,
        setactive,
        checkOpen,
        setcheckOpen,
        testRestaurant,
        settestRestaurant,
        filter,
        setfilter,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};
export default RestaurantContextProvider;
