import {
  TextField,
  Container,
  makeStyles,
  Button,
  CircularProgress,
  Paper,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';
import { React, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RestaurantContext } from '../../Contexts/RestaurantContext';
import json from './customerData.json';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import styles from './OnBoarding.module.css';
import Header from '../Header';
import NoteBox from 'Components/Dashboard/NoteBox';
import Switch from '@mui/material/Switch';

const stringifyError = (error = null, fallback = '') => {
  if (!error) return fallback;

  if (error.type === 'required') {
    return 'Pflichtfeld';
  }
};

const generateUid = (link = '') => {
  let stripped = link.replaceAll(/(https:\/\/)|(http:\/\/)/gm, '').split('.');

  if (stripped.length <= 2) {
    return stripped[0];
  } else {
    return stripped[1];
  }
};

const PRODUCTS = [
  {
    id: 'reservation',
    label: 'Reservation Plugin',
  },
  {
    id: 'followUp',
    label: 'Feedback',
  },
  {
    id: 'menu',
    label: 'Digital Menu',
  },
  {
    id: 'delivery',
    label: 'Lieferdienst',
  },
  {
    id: 'merchandise',
    label: 'Merchandise Shop',
  },
];

const LoadingButton = ({ loading }) => {
  // return loading ? (

  return (
    <Button
      type="submit"
      variant="contained"
      fullWidth
      color="primary"
      disableElevation
      style={{ marginTop: '20px' }}
    >
      {loading ? <CircularProgress color="inherit" size="22px" /> : 'Absenden'}
    </Button>
  );
};

const FIELDS_RESTAURANT = [
  {
    type: 'text',
    label: 'Restaurant ID',
    name: 'id',
    required: false,
    helperText: 'Nur wenn gewünscht',
    minLength: 1,
    maxLength: 100,
    pattern: null,
  },
  {
    type: 'text',
    label: 'Restaurant Name',
    name: 'name',
    required: true,
    minLength: 1,
    maxLength: 80,
    pattern: null,
  },
  {
    type: 'text',
    label: 'Link',
    name: 'link',
    required: false,
    minLength: 1,
    maxLength: 100,
    // pattern: /(http://www.)|(https://www.)|(http://|https://)/gm,
  },
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    required: false,
    minLength: 1,
    maxLength: 80,
    pattern: /^\S+@\S+$/i,
  },
  {
    type: 'tel',
    label: 'Telefon Nr.',
    name: 'phone',
    required: false,
    minLength: 6,
    maxLength: 15,
    // pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
  },
];

const FIELDS_CONTACT = [
  {
    type: 'text',
    label: 'Kontakt Name',
    name: 'contactName',
    required: false,
    minLength: 1,
    maxLength: 100,
    pattern: null,
  },
  {
    type: 'text',
    label: 'Anschrift Straße & Hausnummer',
    name: 'address.street',
    required: true,
    minLength: 1,
    maxLength: 100,
    pattern: null,
  },
  {
    type: 'text',
    label: 'Anschrift Postleitzahl',
    name: 'address.zipCode',
    required: true,
    minLength: 1,
    maxLength: 100,
    pattern: null,
  },
  {
    type: 'text',
    label: 'Anschrift Stadt',
    name: 'address.city',
    required: true,
    minLength: 1,
    maxLength: 100,
    pattern: null,
  },
];

// const FIELDS_PROFILE = [
//   {
//     type: "text",
//     label: "Profil ID",
//     name: "profileId",
//     required: false,
//     minLength: 1,
//     maxLength: 100,
//     pattern: null,
//   },
//   {
//     type: "text",
//     label: "Google Place ID",
//     name: "googlePlaceId",
//     required: false,
//     minLength: 1,
//     maxLength: 100,
//     pattern: null,
//   },
//   {
//     type: "text",
//     label: "Tripadvisor ID",
//     name: "tripAdvisorUrl",
//     required: false,
//     minLength: 1,
//     maxLength: 100,
//     pattern: null,
//   },
//   {
//     type: "text",
//     label: "Area ID",
//     name: "areaId",
//     required: false,
//     minLength: 1,
//     maxLength: 100,
//     pattern: null,
//   },
// ];

const OnBoarding = ({ history }) => {
  const { restaurants } = useContext(RestaurantContext);
  const [loading, setloading] = useState(false);
  const [overwrite, setoverwrite] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [testValue, settestValue] = useState(false);

  const [products, setproducts] = useState(['general']);

  const [success, setsuccess] = useState(false);

  console.log({ errors });

  const onSubmit = (data) => {
    if (!loading) {
      setloading(true);
      handleFetch(data);
    }
    console.log(data);
  };
  const handleFetch = (data) => {
    console.log('data', data);
    axios
      .post(
        'https://europe-west3-schillingroofbarhd.cloudfunctions.net/onboardingV02',
        {
          ...data,
          id: data.id?.toLowerCase() || generateUid(data.link),
          productsOwned: products,
          isTest: testValue,
          overwrite: overwrite,
          // createdAt: Date.now(),
        }
      )
      .then(function (res) {
        setproducts(['general']);
        setsuccess(true);
        if (res) {
        }
        console.log(res);
        setloading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          setloading(false);
          console.log(error.response.data);
          console.log(error.response.status);
        }
      });
  };

  console.log(testValue);

  const handleProductChange = ({ target: { name, checked } }) =>
    setproducts((p) => (checked ? [...p, name] : p.filter((x) => x !== name)));

  if (success) {
    return (
      <div style={{ padding: 20 }}>
        Restaurant wurde erfolgreich erstellt
        <Button onClick={() => setsuccess(false)}>
          Neues Restaurant erstellen
        </Button>
      </div>
    );
  }

  return (
    <Box
      component="div"
      style={{ background: 'var(--color-depth4)', overflow: 'scroll' }}
    >
      <Paper className={styles.container}>
        <Header header="Onboarding" />
        {/* <NoteBox todos={[]} */}
        <Box maxWidth="s" className={styles.root}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                {FIELDS_RESTAURANT.map((f) => (
                  <TextField
                    fullWidth
                    key={f.name}
                    name={f.name}
                    type={f.type}
                    label={f.label}
                    error={errors?.[f.name]}
                    helperText={stringifyError(errors?.[f.name], f.helperText)}
                    style={{ marginBottom: 10 }}
                    inputRef={register({
                      required: f.required,
                      minLength: f.minLength,
                      maxLength: f.maxLength,
                      // pattern: f.pattern,
                    })}
                    className={styles.field}
                  />
                ))}
              </Grid>
              <Grid item sm={12} md={6}>
                {FIELDS_CONTACT.map((f) => (
                  <TextField
                    fullWidth
                    key={f.name}
                    type={f.type}
                    label={f.label}
                    name={f.name}
                    style={{ marginBottom: 10 }}
                    inputRef={register({
                      required: f.required,
                      minLength: f.minLength,
                      maxLength: f.maxLength,
                      // pattern: f.pattern,
                    })}
                    className={styles.field}
                  />
                ))}
              </Grid>
              {/* <Grid item sm={12} md={6}> */}
              {/* {FIELDS_PROFILE.map((f) => (
                  <TextField
                    fullWidth
                    key={f.name}
                    type={f.type}
                    label={f.label}
                    name={f.name}
                    style={{ marginBottom: 10 }}
                    inputRef={register({
                      required: f.required,
                      minLength: f.minLength,
                      maxLength: f.maxLength,
                      // pattern: f.pattern,
                    })}
                    className={styles.field}
                  />
                ))}
              </Grid> */}
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControl style={{ marginTop: 20 }} component="fieldset">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    {PRODUCTS.map((p) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={products.includes(p.id)}
                            onChange={handleProductChange}
                            name={p.id}
                          />
                        }
                        label={p.label}
                        key={p.id}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* <Grid item xs={6}>
                <FormControl style={{ marginTop: 20 }} component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ marginLeft: 5 }}
                          checked={overwrite}
                          onChange={() => setoverwrite((o) => !o)}
                          name={overwrite}
                        />
                      }
                      label="overwrite"
                    />
                  </FormGroup>
                </FormControl>
              </Grid> */}
            </Grid>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Switch
                color="primary"
                onClick={() => settestValue(!testValue)}
              />
              <Typography style={{ paddingTop: 5 }}>
                Test Restaurant?
              </Typography>
              <Switch
                color="primary"
                onClick={() => setoverwrite(!overwrite)}
              />
              <Typography style={{ paddingTop: 5 }}>
                bestehendes Restaurant überschreiben?
              </Typography>
            </div>
            <LoadingButton loading={loading} />
          </form>
        </Box>
      </Paper>
    </Box>
  );
};
export default withRouter(OnBoarding);
