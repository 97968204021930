import React from 'react';
import { List, Dialog, DialogTitle, Checkbox, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const ProductDialog = ({
  onClose = () => {},
  onChange = () => {},
  onSubmit = () => {},
  open,
  setopen,
  productLinks,
  products = [],
  productsOwned = [],
}) => {
  const submitEnable =
    products.length === productsOwned.length &&
    products.every((p) => productsOwned.includes(p))
      ? true
      : false;

  return (
    <Dialog onClose={onClose} open={open}>
      <List>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {productLinks.map((product) => (
            <div key={product.id} style={{ display: 'flex', padding: 10 }}>
              {/* {console.log(products, product.id)} */}
              <Checkbox
                checked={products.includes(product.id)}
                onChange={onChange}
                name={product.id}
              />
              <DialogTitle>{product.label}</DialogTitle>
            </div>
          ))}
        </div>
      </List>
      <Button
        disabled={submitEnable}
        variant="contained"
        color="primary"
        style={{ margin: 20 }}
        onClick={onSubmit}
      >
        Update Produkt
      </Button>
    </Dialog>
  );
};

ProductDialog.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  productLinks: PropTypes.array,
  products: PropTypes.array,
};

export default ProductDialog;
