import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import NewToDo from './NewToDo';

const NoteBox = ({
  todos = [],
  onCheck = (id, done) => {},
  onNew = (newItem = {}) => {},
}) => {
  return (
    <>
      <List todos={todos} onCheck={onCheck} />
      <NewToDo onNew={onNew} />
    </>
  );
};

NoteBox.propTypes = {
  todos: PropTypes.array,
  onNew: PropTypes.func,
  onCheck: PropTypes.func,
};

export default NoteBox;
