import logo from "./logo.svg";
import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import AuthContextProvider from "./Contexts/AuthContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import SignIn from "./Components/SignIn/SignIn";
import Dashboard from "./Components/Dashboard/Dashboard";
import RestaurantContextProvider from "./Contexts/RestaurantContext";
import TopBar from "./Components/TopBar";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import HealthContext from "Contexts/HealthContext";

const Page404 = ({ history }) => {
  history.push("/admin-dashboard/dashboard");

  return <div />;
};

function App() {
  const theme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#10CCB9",
        contrastText: "#252627",
        light: "rgba(11,219,197,0.6)",
      },
      secondary: {
        main: "#f50057",
        contrastText: "#ffffff",
      },
      text: {
        primary: "rgba(37, 38, 39, 0.87)",
        secondary: "rgba(37, 38, 39, 0.54)",
        disabled: "rgba(37, 38, 39, 0.38)",
        hint: "rgba(37, 38, 39, 0.38)",
      },
    },
    typography: {
      h1: {
        fontSize: "2.5rem",
      },
      h2: {
        fontSize: "2rem",
      },
      subtitle1: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
      },
      subtitle2: {
        fontSize: "1.25rem",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContextProvider>
          <RestaurantContextProvider>
            <TopBar />
            <Switch>
              <Route path="/signIn" component={SignIn} exact={true} />
              <Route
                path="/admin-dashboard/:feature"
                component={Dashboard}
                exact={true}
              />
              <Route component={Page404} />
            </Switch>
          </RestaurantContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
