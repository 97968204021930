import React from "react";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  message: {
    minHeight: "50px",
    maxWidth: "50%",
    fontSize: "0.9rem",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
}));

const Conversation = ({ key, data, ts }) => {
  const styles = useStyles();

  return (
    <div key={key}>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          textAlign: "left",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            backgroundColor: "#fed9b7",
          }}
          className={styles.message}
        >
          {data?.text}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          paddingTop: 10,
          justifyContent: "flex-end",
          textAlign: "right",
        }}
      >
        <div
          style={{
            backgroundColor: "#0081a7",
            color: "white",
          }}
          className={styles.message}
        >
          {data?.response}
        </div>
      </div>
    </div>
  );
};

export default Conversation;
