import { React, useContext } from "react";
import {
  Button,
  Container,
  TextField,
  Box,
  ButtonGroup,
  Paper,
} from "@material-ui/core";
import RestaurantInformationContainer from "./RestaurantInformation/Index";
import Header from "../Header";
import LeftRestaurantList from "./LeftRestaurantList";
import styles from "./Dashboard.module.css";
import OnBoarding from "../OnBoarding";
import NavBar from "../NavBar";
import RestaurantList from "./LeftRestaurantList/RestaurantList";
import HealthScreen from "../Health/index";
import ErrorList from "./Error/ErrorList";
import HealthContextProvider from "../../Contexts/HealthContext";
import CallList from "../SIA/index";

const Dashboard = ({ match }) => {
  const { feature } = match.params;
  return (
    <>
      <Box className={styles.container}>
        {/* <RestaurantListContainer /> */}
        <NavBar activeItem={feature} />

        {feature === "onboarding" && <OnBoarding />}

        {feature === "dashboard" && <LeftRestaurantList />}
        {feature === "dashboard" && <RestaurantInformationContainer />}
        <HealthContextProvider>
          {feature === "health" && <HealthScreen />}
        </HealthContextProvider>
        {feature === "sia" && <CallList />}
        {/* {feature === "health" && <ErrorList />} */}
      </Box>
    </>
  );
};

export default Dashboard;
