import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Popover,
  StylesProvider,
  Typography,
} from '@material-ui/core';
import styles from '../NoteBox.module.css';
import moment from 'moment';
import 'moment/locale/de';

const ListElement = ({
  id = '',
  type = '',
  message = '',
  done = false,
  createdAt = '',
  sender = {},
  onCheck = (id, done) => {},
  scrollRef = null,
}) => {
  return (
    <div className={styles.listElement} ref={scrollRef}>
      {type === 'todo' && (
        <Checkbox
          checked={done}
          onChange={(e) => onCheck(id, e.target.checked)}
        />
      )}
      {type === 'note' && <div style={{ marginLeft: 40 }}></div>}
      <p
        style={{
          paddingTop: 11,
          flexGrow: 10,
          textDecoration: done ? 'line-through' : null,
        }}
      >
        {message}
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <Typography variant="caption" color="textSecondary">
          {`by:  ${sender.displayName}`}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {moment(createdAt).calendar()}
        </Typography>
      </div>
    </div>
  );
};

ListElement.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  done: PropTypes.bool,
  createdAt: PropTypes.string,
  sender: PropTypes.object,
  onCheck: PropTypes.func,
  isMe: PropTypes.bool,
  scrollRef: PropTypes.any,
};

export default ListElement;
