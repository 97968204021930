import { React, useState, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Paper,
  Box,
  Avatar,
  ListItemAvatar,
  Button,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from './RestaurantList.module.css';

const ListItemLink = (props) => {
  return <ListItem button component="a" {...props} />;
};

const RestaurantList = ({
  restaurants,
  current,
  setcurrent,
  handleOpen,
  open,
  onCurrent,
  filter,
  setfilter,
}) => {
  // console.log(restaurants);
  // console.log(open);
  // console.log(current);

  return (
    <>
      <Box>
        <List className={styles.rlist}>
          <div className={styles.expand} style={{}}>
            {/* <ListItemIcon>
              <Avatar src="/restaurantMarker.png" />
            </ListItemIcon> */}
            <Button
              disableElevation
              variant="contained"
              onClick={() => setfilter('all')}
              color={filter === 'all' ? 'primary' : 'default'}
            >
              All
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setfilter('tests')}
              color={filter === 'tests' ? 'primary' : 'default'}
            >
              Test
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setfilter('live')}
              color={filter === 'live' ? 'primary' : 'default'}
            >
              Live
            </Button>
          </div>

          <List disablePadding>
            {restaurants &&
              restaurants
                ?.filter((f) => {
                  if (filter === 'all') {
                    return f;
                  } else if (filter === 'tests') {
                    return f?.isTest;
                  } else if (filter === 'live') {
                    return f?.live && !f?.isTest && f?.status === 'production';
                  }
                })
                .sort((a, b) => (a.name > b.name ? 1 : 0))
                .map((res) => (
                  // <Paper elevation={4} className={styles.paper}>
                  <ListItem
                    key={res?.id}
                    className={styles.nested}
                    selected={current === res?.id}
                  >
                    <ListItemLink onClick={() => onCurrent(res?.id)}>
                      {/* <ListItemAvatar>
                        <Avatar>{res.id[0]}</Avatar>
                      </ListItemAvatar> */}
                      <ListItemText
                        primary={res?.name}
                        secondary={res?.id}
                        style={{
                          textAlign: 'center',
                          color:
                            current === res?.id
                              ? 'var(--color-secondary)'
                              : 'var(--color-text)',
                        }}
                      />
                    </ListItemLink>
                  </ListItem>
                  // </Paper>
                ))}
          </List>
        </List>
      </Box>
    </>
  );
};

export default RestaurantList;
