import React, { useContext, useState, useEffect } from "react";
import useCallList from "CustomHooks/useCallList";
import {
  Button,
  Paper,
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import IDList from "./List";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";
import CallInformation from "./CallInformation";
import { ChatBubbleSharp, MessageSharp } from "@material-ui/icons";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "calc(100vh - 80px)",
    width: "calc(100vw - 200px)",
    background: "var(--color-depth4)",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    paddingLeft: 15,
  },
  listContainer: {
    overflow: "scroll",
    minWidth: "310px",
    paddingRight: 0,
  },
  header: {
    fontWeight: "700",
    color: "var(--color-text)",
  },
  callInfo: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    marginLeft: 10,
  },
}));

const CallList = () => {
  const styles = useStyles();

  const [filter, setfilter] = useState("all");
  const callList = useCallList(filter);
  const [currentCall, setcurrentCall] = useState(null);
  const [open, setopen] = useState(false);

  const callData = !!currentCall
    ? callList?.find((c) => c.id === currentCall)
    : null;

  const handleOpen = () => {
    setopen(!open);
  };
  console.log(filter);
  const handleFilter = (filter) => {
    setfilter(filter);
    setopen(!open);
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.listContainer}>
        <div
          style={{
            display: "flex",
            padding: 20,
            paddingRight: 15,
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" component="h1" className={styles.header}>
            Call History
          </Typography>
          <div
            style={{
              marginTop: 15,
              height: "40px",
              minWidth: "200px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ flexDirection: "row" }}>
              <Button
                style={{ marginRight: 15 }}
                disableElevation
                variant="contained"
                onClick={() => setfilter("all")}
                color={filter === "all" ? "primary" : "default"}
              >
                All
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={() => setfilter("failed")}
                color={filter === "failed" ? "primary" : "default"}
              >
                Failed
              </Button>
              <IconButton
                style={{ marginLeft: 15 }}
                onClick={() => setfilter("chat")}
                color={filter === "chat" ? "primary" : "default"}
              >
                <MessageSharp size={20} />
              </IconButton>
              <IconButton onClick={handleOpen}>
                <StarOutlineIcon fontSize="medium" />
              </IconButton>
            </div>
          </div>

          <div style={{ flexDirection: "row", padding: 0 }}>
            {open && (
              <Accordion elevation={1} style={{ marginTop: -35 }}>
                <AccordionDetails>
                  <div style={{ flexDirection: "column" }}>
                    <IconButton
                      onClick={() => handleFilter("rating_1")}
                      color={filter === "rating_1" ? "primary" : "default"}
                    >
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleFilter("rating_2-3")}
                      color={filter === "rating_2-3" ? "primary" : "default"}
                    >
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleFilter("rating_4-5")}
                      color={filter === "rating_4-5" ? "primary" : "default"}
                    >
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                      <StarOutlineIcon
                        style={{ height: "18px", width: "18px" }}
                      />
                    </IconButton>

                    <IconButton
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "16px",
                        width: "16px",
                      }}
                      onClick={() => setopen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
        <IDList
          data={callList}
          callData={callData}
          currentCall={currentCall}
          setcurrentCall={setcurrentCall}
        />
      </Paper>

      <Paper className={styles.callInfo}>
        {!!callData && (
          <>
            <CallInformation callData={callData} />
          </>
        )}
      </Paper>
    </div>
  );
};

export default CallList;
