import React, { useContext, useEffect, useState, useMemo } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";
import Event from "./Event";
import { db, FieldValue } from "./../../../utils/firebase";
import Conversation from "./Conversation";
import { relativeTimeRounding } from "moment";
import { GpsFixed } from "@material-ui/icons";
import { fromDate } from "../../../utils/helperFunctions";
import Rating from "@mui/material/Rating";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "calc(100vw - 200px)",
    background: "var(--color-depth4)",
    display: "flex",
    flexDirection: "column",
    padding: 15,
  },
  viewContainer: {
    width: "100%",
  },
  callCard: {
    margin: 15,
    padding: 15,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  resultCard: {
    margin: 15,
    padding: 15,
    flexGrow: 20,
    width: "100%",
  },
  intentCard: {
    margin: 15,
    marginTop: 5,
    padding: 15,
    background: "var(--color-depth3)",
    overflow: "scroll",
    flexGrow: 10,
  },

  attribute: {
    paddingTop: 10,
  },
  attributeHeader: {
    paddingBottom: 10,
    fontWeight: "700",
  },
}));

const CallInformation = ({ callData }) => {
  const styles = useStyles();
  const count = 0;
  const [rating, setrating] = useState(0);
  const [recordingUrl, setrecordingUrl] = useState("");
  const [showRecording, setshowRecording] = useState(false);

  useEffect(() => {
    setshowRecording(false);
    setrecordingUrl(callData?.RecordingUrl);
    setrating(callData?.rating ?? 0);

    if (callData?.RecordingUrl) {
      setTimeout(() => setshowRecording(true), 1000);
    }
  }, [callData]);

  console.log(recordingUrl);

  const callPaths = [
    ...(callData?.conversations?.map((c) => ({
      ts: c.ts,
      type: "conversation",
      data: c,
    })) || []),
    ...(callData?.events?.map((c) => ({ ts: c.ts, type: "event", data: c })) ||
      []),
  ].sort((a, b) => a.ts - b.ts);

  const lastElement = callPaths?.[callPaths.length - 1];

  const handleRating = async (event, newValue) => {
    await db.collection("calls").doc(callData.id).update({ rating: newValue });
  };

  const handleSuccess = () => {
    const callRef = db.collection("calls").doc(callData.id);
    callRef.update({
      success: !callData.success,
      events: FieldValue.arrayUnion({
        id: "ADMIN",
        result: `Wurde vom Admin ${
          callData?.success ? "auf Fehlgeschlagen" : "auf Erfolg"
        } gesetzt`,
        success: !callData.success,
      }),
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: 20,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" component="h1">
          {`${
            !!callData.guest && callData.guest.name !== "ja"
              ? callData?.guest.name
              : callData?.callerId
          }`}
        </Typography>
        <Rating value={rating} onChange={handleRating} />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Paper className={styles.callCard}>
          <Typography variant="h5" className={styles.attributeHeader}>
            Anrufer
          </Typography>
          <Typography
            variant="inherit"
            className={styles.attribute}
          >{`CallerID: ${callData?.callerId}`}</Typography>
          <Typography variant="inherit" className={styles.attribute}>
            Gast:{" "}
            {callData?.guest?.name ?? "Kein Gast gefunden mit dieser Nummer"}
          </Typography>
          <Typography variant="inherit" className={styles.attribute}>
            Datum: {`${fromDate(new Date(callData?.date), false)}`}
          </Typography>
          <Typography
            variant="inherit"
            className={styles.attribute}
          >{`Uhrzeit: ${callData?.time}`}</Typography>
          <Typography variant="inherit" className={styles.attribute}>
            Session-ID: {callData?.id ?? "ID"}
          </Typography>
        </Paper>
        <Paper className={styles.resultCard}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography variant="h5" className={styles.attributeHeader}>
              Letzte Aktivität
            </Typography>
            <Button
              style={{ ustifyContent: "flex-end" }}
              disableElevation
              variant="contained"
              onClick={handleSuccess}
            >
              {callData.success ? "Fehlgeschlagen?" : "Erfolgreich?"}
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {lastElement?.type === "conversation" && (
              <>
                <Typography variant="inherit" className={styles.attribute}>
                  Nutzer: {lastElement.data.text}
                </Typography>
                <Typography variant="inherit" className={styles.attribute}>
                  Antwort: {lastElement.data.response}
                </Typography>
              </>
            )}
            {lastElement?.type === "event" && (
              <>
                <Typography variant="inherit" className={styles.attribute}>
                  Event: {lastElement.data.result}
                </Typography>
              </>
            )}
            <Typography
              variant="inherit"
              style={{
                color: !callData.success ? "red" : "green",
                fontWeight: 500,
              }}
              className={styles.attribute}
            >
              Result: {!callData?.success ? "Failed" : "Erfolgreich"}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {!!callData.voicemail && (
                <div style={{ marginRight: "10px" }}>
                  <Typography style={{ margin: "5px 0", fontWeight: "bolder" }}>
                    Mailbox Nachricht:
                  </Typography>
                  <audio controls>
                    <source src={callData.voicemail.audio} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
              {showRecording && (
                <div>
                  <Typography style={{ margin: "5px 0", fontWeight: "bolder" }}>
                    Tonaufnahme:
                  </Typography>
                  <audio controls>
                    <source src={recordingUrl} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
            {/* <Typography
              variant="inherit"
              style={{
                color: !callData.success ? "red" : "green",
                fontWeight: 500,
              }}
              className={styles.attribute}
            >
              durch {!callData?.success ? "Failed" : "Erfolgreich"}
            </Typography> */}
          </div>
        </Paper>
      </div>
      <Paper className={styles.intentCard}>
        <div>
          {callPaths?.map(({ ts, type, data }) => {
            if (type === "conversation") {
              return <Conversation key={ts} data={data} ts={ts} />;
            } else {
              return <Event key={ts} data={data} ts={ts} />;
            }
          })}
        </div>
        {/* <>
            
              <Typography
                key={e.id}
                variant="h6"
                className={styles.attribute}
              >{`Intent ID: ${e?.id}`}</Typography>
              <Typography
                key={e.id}
                variant="h6"
                className={styles.attribute}
              >{`Result: ${e?.result}`}</Typography>
            </>
          ))} */}
      </Paper>
    </>
  );
};

export default CallInformation;
