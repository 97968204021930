import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Paper, Typography, List, ListItem, Card, CardHeader, IconButton } from '@material-ui/core';
import { WebRounded, SupervisorAccount } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styles from './RestaurantInformation.module.css';
import Header from '../../Header';
import NoteBox from '../NoteBox';
import ProductDialog from './Modal/index';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';

/*
  restaurants: Array
  currentRestaurant: id || null
*/

const RestaurantInformation = ({
  current,
  setcurrent,
  statusOptions,
  productLinks,
  open,
  setopen,
  products,
  setproducts,
  restaurant = null,
  restaurantProducts = [],
  customerRef,
  updated,
  checkOpen,
  onCheckOpen,
  testRestaurant,
  settestRestaurant,
}) => {
  let { id = '', name = '', customerInfo = {}, productsOwned = [], isTest = false, status = '' } = restaurant || {};

  let { address, contactName, email, phoneNumber, url } = customerInfo;

  console.log({ restaurant });
  console.log({ current });

  const handleClose = () => {
    console.log({ open });
    setopen(false);
  };

  const handleNew = (newItem = {}) => {
    const todos = restaurant?.todos || [];

    todos.push(newItem);

    customerRef.update({
      todos,
      updatedAt: Date.now(),
    });
  };
  const handleStatusChange = (status) => () => customerRef.update({ status, updatedAt: Date.now() });

  const handleToDoCheck = (id, done) => {
    const todos = restaurant?.todos?.map((t) => (t.id === id ? { ...t, done } : t));

    if (!todos) return;
    customerRef.update({ todos, updatedAt: Date.now() });
  };

  const handleChangeProduct = ({ target: { name, checked } }) => {
    // console.log({ e });
    if (checked) {
      setproducts((p) => [...p, name]);
    } else {
      setproducts((p) => p.filter((p) => p !== name));
    }
  };

  const handleTest = async (val) => {
    settestRestaurant(val);

    await customerRef.update({ isTest: val });
  };

  const handleSubmit = () => {
    setopen(false);

    customerRef.update({ productsOwned: products });
  };

  const handleStatusDelete = () => {
    customerRef.update({ status: null, updatedAt: Date.now() });
  };

  useEffect(() => {
    settestRestaurant(isTest ?? false);
  }, [restaurant]);

  if (!restaurant && !checkOpen) return <></>;

  return (
    <Paper className={styles.rinfo}>
      <Header header={!name ? 'Dashboard' : name && checkOpen ? 'HealthCheck' : name} onClose={() => setcurrent(null)} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ marginTop: '10px' }} variant="inherit" color="textSecondary">
          Restaurant-ID: {id}
        </Typography>
        <Typography style={{ marginTop: '10px' }} variant="caption" color="textSecondary">
          Zuletzt aktualisiert: {updated}
        </Typography>
      </div>
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={4} alignContent="stretch" justify="center" style={{ width: '100%' }}>
          <Grid item md={4}>
            {/* <div className={styles.upperBoxes}> */}
            <Card className={styles.infoBox}>
              <CardHeader title="Actions" />
              <div className={styles.cardAlign}>
                <Button onClick={() => setopen(true)} style={{ marginTop: '20px' }}>
                  Produkte bearbeiten
                </Button>
                <ProductDialog
                  open={open}
                  setopen={setopen}
                  productLinks={productLinks}
                  products={products}
                  productsOwned={productsOwned}
                  onClose={handleClose}
                  onChange={handleChangeProduct}
                  onSubmit={handleSubmit}
                />

                {/* <Button>Daten exportieren</Button> */}
                <Button onClick={() => onCheckOpen()}>Health Check durchführen</Button>
              </div>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card className={styles.infoBox}>
              <CardHeader title="Produkte" />

              <div className={styles.cardAlign}>
                <Button startIcon={<SupervisorAccount />} target="_blank" href={`https://app.gastronaut.ai/${restaurant?.id}`}>
                  Dashboard
                </Button>
                {restaurantProducts.map((product) => (
                  <Button startIcon={product.icon} key={product.id} href={product?.link(id)} target="_blank">
                    {product.label}
                  </Button>
                ))}
              </div>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card className={styles.infoBox}>
              <CardHeader title="Adresse" className={styles.infoBoxHead} />
              {restaurant && (
                <div className={styles.cardAlign} style={{ paddingLeft: '20px' }}>
                  <Typography style={{ marginBottom: '10px' }} color="textSecondary" variant="inherit">
                    {contactName}
                  </Typography>
                  <Typography style={{ marginBottom: '10px' }} color="textSecondary" variant="inherit">
                    {phoneNumber}
                  </Typography>
                  <Typography color="textSecondary" variant="inherit">
                    {address}
                  </Typography>

                  <div style={{ marginTop: '10px', display: 'block' }}>
                    <Typography variant="inherit">{email}</Typography>

                    <div>
                      <Button startIcon={<WebRounded />} href={url} target="_blank">
                        Webseite
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Card>
            {/* </div> */}
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ width: '100%' }}>
          <Grid item sm={6}>
            <Paper style={{ marginLeft: 10 }} className={styles.textBox}>
              {/* <NotizBox /> */}
              <NoteBox todos={restaurant?.todos} onNew={handleNew} onCheck={handleToDoCheck} />
            </Paper>
          </Grid>

          <Grid item sm={6}>
            <Paper className={styles.textBox}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader title="Status" />
                <div>
                  <IconButton onClick={() => handleStatusDelete()}>
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Switch checked={testRestaurant} color="primary" onClick={() => handleTest(!testRestaurant)} />
                <Typography style={{ paddingTop: 5 }}>Test Restaurant?</Typography>
              </div>

              <List>
                <ToggleButtonGroup className={styles.toggleGroup}>
                  {statusOptions.map((s) => (
                    <ListItem key={s.id} selected={status === s.id}>
                      <ToggleButton
                        style={{
                          color: status === s.id ? 'var(--color-secondary)' : 'var(--color-text)',
                        }}
                        className={styles.toggleButton}
                        onClick={handleStatusChange(s.id)}
                        selected={status === s.id}
                      >
                        <Typography>{s.title}</Typography>
                      </ToggleButton>
                    </ListItem>
                  ))}
                </ToggleButtonGroup>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default RestaurantInformation;
