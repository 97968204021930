import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListElement from './ListElement';
import styles from '../NoteBox.module.css';

const sortHelper = (a, b) => {
  if (a.done && !b.done) {
    return -1;
  } else if (!a.done && b.done) {
    return 1;
  } else {
    return a.createdAt - b.createdAt;
  }
};

const List = ({ todos = [], onCheck = (id, done) => {} }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: 'smooth',
      });
    }
    // console.log('REF!', scrollRef.current);
  }, [scrollRef.current]);

  return (
    <div className={styles.list}>
      {todos.sort(sortHelper).map((item) => (
        <ListElement
          scrollRef={scrollRef}
          onCheck={onCheck}
          key={item.id}
          {...item}
        />
      ))}
    </div>
  );
};

List.propTypes = {
  todos: PropTypes.array,
  onCheck: PropTypes.func,
};

export default List;
