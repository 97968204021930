import React from "react";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: "0.9rem",
    borderRadius: "12px",
    display: "flex",

    alignItems: "center",
    padding: 10,
  },
}));

const Event = ({ key, data, ts }) => {
  const styles = useStyles();
  return (
    <div key={key}>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            // borderColor: "#fed9b7",
            // borderWidth: "2px",
            // borderStyle: "solid",
            textAlign: "center",
            background: "#252627",
            color: "white",
          }}
          className={styles.message}
        >
          {data?.result}
        </div>
      </div>
    </div>
  );
};

export default Event;
