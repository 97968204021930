import React, { useContext, useState } from "react";
import useCallList from "../../../CustomHooks/useCallList";
import {
  Button,
  List,
  ListItem,
  Paper,
  Typography,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 10,
    width: "350px",
    overflow: "scroll",
  },
  listItem: {},
}));

const IDList = ({ data, currentCall, setcurrentCall }) => {
  const styles = useStyles();

  return (
    <List className={styles.list}>
      {data?.map((call) => (
        <ListItem
          id={call.id}
          key={call.id}
          selected={currentCall === call.id}
          onClick={() => setcurrentCall(call.id)}
          className={styles.listItem}
        >
          <ListItemText
            style={{ color: !call.success ? "red" : "" }}
            primary={`${
              !!call.guest && call.guest.name !== "ja"
                ? call?.guest.name
                : call?.callerId
            }`}
            secondary={call.dateStr}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default IDList;
