import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Typography,
  Paper,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemText,
  Icon,
  ListItemIcon,
  Avatar,
} from '@material-ui/core';
import Header from '../../../Components/Header';
import useSystemCheck from '../../../CustomHooks/useSystemCheck';
import styles from './RestaurantInformation.module.css';
import CachedIcon from '@mui/icons-material/Cached';
import CommentIcon from '@mui/icons-material/Comment';

import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HelpIcon from '@mui/icons-material/Help';

import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const LoadingData = ({ message, loading }) => {
  return (
    <>
      <Typography style={{ paddingBottom: 10 }}>{message}</Typography>
      <CircularProgress size={16} />
    </>
  );
};

const Severity = (key) => {
  return (
    <>
      {key === 'error' && <ErrorIcon />}
      {key === 'warning' && <ReportProblemIcon style={{ color: 'orange' }} />}
      {key === 'suggestion' && <HelpIcon style={{ color: 'yellow' }} />}
    </>
  );
};

const SystemCheck = ({ restaurantId, onCheckOpen, restaurantName }) => {
  const { state, healthCheck, loading, reload, message } =
    useSystemCheck(restaurantId);

  const available = Object.keys(state).length;
  // console.log({ restaurantId });
  console.log('HEALTH', healthCheck);
  // console.log('STATE:', state);

  return (
    <Paper style={{ height: '100%', padding: 10 }}>
      <Header
        header={`HealthCheck: ${restaurantName}`}
        onClose={() => onCheckOpen()}
      />
      <Paper style={{ height: '85%', margin: 20, marginTop: 30 }}>
        {!available && (
          <Box
            style={{
              display: 'flex',
              height: '80%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {!message && (
              <>
                <Typography>
                  Bisher sind keine Health Check Daten vorhanden
                </Typography>
                <Button
                  style={{ marginTop: 10 }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => reload()}
                >
                  Check durchführen
                </Button>
              </>
            )}
            {message && <LoadingData message={message} loading={loading} />}
          </Box>
        )}
        {available && (
          <>
            <div
              style={{
                padding: 10,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton onClick={() => reload()}>
                <CachedIcon />
              </IconButton>
            </div>
            <div style={{ padding: 15, margin: 15, width: '100%' }}>
              <List style={{ overflow: 'scroll', height: '450px' }}>
                {healthCheck
                  ?.sort((a, b) => (a.value ? 1 : 0) - (b.value ? 1 : 0))
                  .map((item) => (
                    <ListItem style={{ width: '100%' }}>
                      <Icon
                        style={{ alignItems: 'flex-start', marginRight: 10 }}
                      >
                        {item.value && <CheckIcon style={{ color: 'green' }} />}
                        {!item.value && <CancelIcon style={{ color: 'red' }} />}
                      </Icon>
                      <ListItemText style={{ minWidth: '270px' }}>
                        {item.title}
                      </ListItemText>
                      <ListItemIcon>
                        <Icon>
                          {item.severity === 'error' && (
                            <ErrorIcon style={{ color: 'red' }} />
                          )}
                          {item.severity === 'warning' && (
                            <ReportProblemIcon style={{ color: 'orange' }} />
                          )}
                          {item.severity === 'suggestion' && (
                            <HelpIcon style={{ color: 'dodgerblue' }} />
                          )}
                        </Icon>
                      </ListItemIcon>
                    </ListItem>
                    // <div
                    //   style={{
                    //     display: 'flex',
                    //     flexDirection: 'row',
                    //   }}
                    // >
                    //   <Typography variant="h6">{item.title}</Typography>
                    //   <Icon
                    //     style={{ display: 'flex', justifyContent: 'flex-end' }}
                    //   >
                    //     <Severity key={item.severity} />
                    //   </Icon>
                    // </div>
                    // <ListItemText>{'true'}</ListItemText>
                    // </ListItem>
                  ))}
              </List>
            </div>
          </>
        )}
      </Paper>
    </Paper>
  );
};

export default SystemCheck;
