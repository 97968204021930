import { React, useState, useContext } from 'react';
import { RestaurantContext } from '../../../Contexts/RestaurantContext';
import { AuthContext } from '../../../Contexts/AuthContext';
import RestaurantList from './RestaurantList';

const RestaurantListContainer = () => {
  const { restaurants, current, setcurrent, setcheckOpen, filter, setfilter } =
    useContext(RestaurantContext);
  const { data, signOutUser } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [loading, setloading] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCurrent = (id) => {
    setcurrent(id);
    setcheckOpen(false);
  };

  return (
    <RestaurantList
      restaurants={restaurants}
      current={current}
      setcurrent={setcurrent}
      handleOpen={handleOpen}
      open={open}
      onCurrent={handleCurrent}
      filter={filter}
      setfilter={setfilter}
    />
  );
};

export default RestaurantListContainer;
